import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import '../style/style.css';
import { t } from 'i18next';
import main_img1 from '../img/ic_main_0.png';
import main_img2 from '../img/ic_main_1.png';
import tech1 from '../icon/ic_main_1.svg';
import tech2 from '../icon/ic_main_2.svg';
import tech3 from '../icon/ic_main_3.svg';
import ic_cert from '../icon/ic_cert.png';

/**
 * xs, extra-small: 0px
    sm, small: 600px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1920px
 */

const useStyles = makeStyles((theme) => ({
    
    mainBox: {
        maxWidth: "1200px",
        width: "85%",
        margin: "auto",
        background: "#fff",
        fontFamily: "NanumSquare",
        [theme.breakpoints.down('md')]: {
            width: "85%",
            textAlign: "center",
            flexDirection: "column",
        },
        [theme.breakpoints.down('sm')]: {
            width: "85%",
            textAlign: "center",
            margin: "auto",
            flexDirection: "column",
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column",
            textAlign: "center",
            width: "90%",
            margin: "auto",
        },
    },
    mainBox__back: {
        maxWidth: "1200px",
        width: "75%",
        margin: "auto",
        fontFamily: "NanumSquare",
        [theme.breakpoints.down('md')]: {
            width: "70%",
            textAlign: "center",
            flexDirection: "column",
        },
        [theme.breakpoints.down('sm')]: {
            width: "70%",
            textAlign: "center",
            margin: "auto",
            flexDirection: "column",
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column",
            textAlign: "center",
            width: "90%",
            margin: "auto",
        },
    },
    mainBox__header: {
        [theme.breakpoints.down('xs')]: {
            fontSize: "1.7rem",
        },
    },
    mainBox__content: {
        width: "105%",
        maxWidth: "1300px",
        marginLeft: "-25px",
        [theme.breakpoints.down('sm')]: {
            
            width: "100%",
            margin: "auto",
        },
        [theme.breakpoints.down('xs')]: {
            
            width: "100%",
            margin: "auto",
        },
    },
    inlineBox: {
        display: "flex",
        flexDirection: "row",
        minHeight: "250px",
        marginBottom: "2rem",
        [theme.breakpoints.down('md')]: {
            width: "100%",
            marginTop: "10px",
            textAlign: "center",
            flexDirection: "column",
            margin: "auto",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginTop: "10px",
            textAlign: "center",
            flexDirection: "column",
            margin: "auto",
        },
        [theme.breakpoints.down('xs')]: {
            margin: "0",
            width: "100%",
        },
    },
    inlineBox__first: {
        display: "flex",
        flexDirection: "row",
        marginBottom: "2rem",
        [theme.breakpoints.down('md')]: {
            display: "flex",
            flexDirection: "row",
            marginBottom: "2rem",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginTop: "10px",
            flexDirection: "column",
            margin: "auto",
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column",
            margin: "auto",
            width: "100%",
        },
    },
    inlineBox__cert: {
        maxWidth: "40%",
        width: "100%",
        minWidth: "300px",
        [theme.breakpoints.down('md')]: {
            maxWidth: "90%",
            textAlign: "center",
            marginBottom: "2%",
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: "100%",
            marginBottom: "2%",
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: "100%",
            minWidth: "200px",
        },
    },inlineBox__cert__half: {
        maxWidth: "60%",
        width: "100%",
        minWidth: "300px",
        marginLeft: "50px",
        [theme.breakpoints.down('md')]: {
            maxWidth: "70%",
            margin: "auto",
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: "100%",
            margin: "auto",
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: "100%",
            minWidth: "200px",
            margin: "0",
        },
    },
    inlineBox__cert__tit: {
        fontFamily: "Inter",
        fontWeight: "600",
        fontSize: "36px",
        lineHeight: "44px",
        color: "#4D4D4D",        
    },
    inlineBox__cert__txt: {
        fontFamily: "Noto Sans KR",
        fontWeight: "400",
        fontSize: "20px",
        lineHeight: "28px",
        color: "#4D4D4D",
    },
    inlineBox__half: {
        maxWidth: "75%",
        width: "100%",
        minWidth: "350px",
        [theme.breakpoints.down('md')]: {
            maxWidth: "90%",
            margin: "auto",
            marginBottom: "2%",
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: "100%",
            margin: "auto",
            marginBottom: "2%",
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: "100%",
            minWidth: "200px",
            margin: "0",
        },
    },
    inlineBox__half__img: {
        maxWidth: "75%",
        width: "90%",
        minWidth: "350px",
        [theme.breakpoints.down('md')]: {
            maxWidth: "90%",
            marginBottom: "2%",
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "2%",
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: "100%",
            minWidth: "200px",
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    inlineBox__half__content: {
        width: "100%",
        minWidth: "350px",
        alignSelf: "center",
        marginLeft: "20px",
        [theme.breakpoints.down('md')]: {
            maxWidth: "90%",
            margin: "auto",
            marginBottom: "2%",
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: "100%",
            margin: "auto",
            marginBottom: "2%",
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: "100%",
            minWidth: "200px",
            margin: "0",
        },
    },
    inlineBox__half__content__right: {
        width: "100%",
        minWidth: "350px",
        alignSelf: "center",
        marginRight: "20px",
        [theme.breakpoints.down('md')]: {
            maxWidth: "90%",
            margin: "auto",
            marginBottom: "2%",
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: "100%",
            margin: "auto",
            marginBottom: "2%",
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: "100%",
            minWidth: "200px",
            margin: "0",
        },
    },
    inlineBox__half__content__tit: {
        fontFamily: "Inter",
        fontWeight: "600",
        fontSize: "36px",
        lineHeight: "44px",
        color: "#4D4D4D",        
    },
    inlineBox__half__content__tit__back: {
        height: "30px",
        backgroundColor: "#E6F2FF",
    },
    inlineBox__half__content__txt: {
        fontFamily: "Noto-SansM",
        fontWeight: "400",
        fontSize: "20px",
        lineHeight: "28px",
        color: "black",
    },
    inlineBox__half__p: {
        maxWidth: "600px",
        width: "100%",
        height: "100%",
        maxHeight: "352px",
        marginLeft: "5%",
        color: "#323232",
        fontSize: "15px",
        lineHeight: "2",
        fontWeight: "200",
        whiteSpace: "pre-line",
        [theme.breakpoints.down('md')]: {
            maxWidth: "90%",
            margin: "auto",
            lineHeight: "2",
            textAlign: "left",
            marginBottom: "10%",
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: "100%",
            width: "100%",
        },
    },
    
    inlineBox__half__p__first: {
        maxWidth: "600px",
        width: "100%",
        height: "100%",
        maxHeight: "800px",
        marginLeft: "5%",
        color: "#323232",
        fontSize: "15px",
        lineHeight: "2",
        fontWeight: "200",
        whiteSpace: "pre-line",
        [theme.breakpoints.down('md')]: {
            maxWidth: "90%",
            margin: "auto",
            lineHeight: "2",
            textAlign: "left",
            marginBottom: "10%",
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: "100%",
            width: "100%",
        },
    },
    imgSource: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
        [theme.breakpoints.down('md')]: {
            width: "80%",
            margin: "auto",
        },
        
    },
    fontMain: {
        fontSize: "18px",
        fontWeight: "700",
        color: "#323232",
    },
    fontMain__sub: {
        fontSize: "15px",
        color: "#323232",
        lineHeight: "2",
        [theme.breakpoints.down('xs')]: {
            maxWidth: "100%",
            width: "100%",
        },
    },
    h4Style: {
        fontSize: "3.3rem",
        fontFamily: "GmarketSans",
        fontWeight: "bold",
        lineHeight: "1.7",
        letterSpacing: "-0.98px",
        color: "#e1e1e1",
        [theme.breakpoints.down('sm')]: {
            lineHeight: "1.3",
            marginBottom: "10px",
        },
        [theme.breakpoints.down('xs')]: {
            lineHeight: "1.3",
            marginBottom: "10px",
            fontSize: "2.3rem",
        },
    },
    content__detail: {
        [theme.breakpoints.down('xs')]: {
            

        },
    },
    content__detail__vert: {
        [theme.breakpoints.down('xs')]: {
        },
    },
    inlineBox__vert__content__subtit: {
        fontFamily: "Noto-Sans-KR",
        fontWeight: "700",
        fontSize: "28px",
        lineHeight: "36px",
        color: "#4D4D4D",
    },
    inlineBox__vert__content__txt: {
        fontFamily: "Inter",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "23.17px",
        color: "#4D4D4D",
    },
    box_content: {
        // marginTop: "30px",
    },
    fontDiv: {
        marginBottom: "20px",
    }, mainBox__left: {
        marginLeft: "10px"
    },
    
}));

export default function ResearchTest() {
    
    const classes = useStyles();
    const media960 = useMediaQuery('(max-width:960px)');
    sessionStorage.setItem('imgOn', false);

    return (
        <div >
            <div className="sub-bg-wrap-tech sub-bg-wrap fs_32">
                <div className="content">
                    <span>{t('tech1')}</span>
                </div>
            </div>
            <div className='py-13' data-aos="fade-up">
                <div className={classes.mainBox}>
                    <div className={classes.box_content}>
                        <div className={classes.content__detail}>
                            <div className={classes.inlineBox__first}>
                                <div className={classes.inlineBox__half__img}  >
                                    <img className={classes.imgSource} src={main_img1} alt="profile"  /> 
                                </div>
                                <div className={classes.inlineBox__half__content}>
                                    <h2 className='tech_tit'> <span className="tech_back">{t('tech2')} </span></h2><br/>
                                    <p className='tech_sub'> 
                                        {t('tech3')} 
                                        <br/><br/>
                                        {t('tech4')} 
                                    </p>
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
            </div>
            <div className='py-12' style={{backgroundColor: "#F6F7F9"}} data-aos="fade-up">
                <div className={classes.mainBox__back}>
                    <div className={classes.box_content}>
                        <div className={classes.content__detail__vert} >
                            <div className='mb-10'>
                                <h2 className='tech_app_tit'>{t('tech5')}</h2>
                                <p className='tech_app_sub'>{t('tech6')}</p>
                            </div>
                            <div className='mt-10'>
                                <div >
                                    <div className='d-flex'>
                                        <img src={tech1} className='ic_tech me-3' alt='tech'/>
                                        <p className='color_default tech_txt fw-bold my-auto'> {t('tech7')} </p><br/>
                                    </div>
                                    <p className='tech_txt_mini mt-5'> 
                                        {t('tech8')} 
                                    </p>
                                </div>
                            </div>
                            <div className='mt-10'>
                                <div>
                                    <div className='d-flex'>
                                        <img src={tech2} className='ic_tech me-3' alt='tech'/>
                                        <p className='color_default tech_txt fw-bold my-auto'> {t('tech9')} </p><br/>
                                    </div>
                                    <p className='tech_txt_mini mt-5'> 
                                        {t('tech10')} 
                                    </p>
                                </div>
                            </div>
                            <div className='mt-10'>
                                <div>
                                    <div className='d-flex'>
                                        <img src={tech3} className='ic_tech me-3' alt='tech'/>
                                        <p className='color_default tech_txt fw-bold my-auto'> {t('tech11')} </p><br/>
                                    </div>
                                    <p className='tech_txt_mini mt-5'> 
                                        {t('tech12')} 
                                    </p>
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
            </div>
            <div className='py-12'>
                <div className={classes.mainBox} data-aos="fade-up">
                    <div className={classes.box_content}>
                        <div className={classes.content__detail}>
                            <div className={classes.inlineBox__first}>
                                <div className={classes.inlineBox__half__content__right}>
                                    <h2 className='tech_tit'> 
                                    <span className="tech_back">{t('tech13')} </span></h2><br/>
                                    <p className='tech_sub'> 
                                        {t('tech14')} 
                                        <br/><br/>
                                        {t('tech15')} 
                                    </p>
                                </div>
                                <div className={classes.inlineBox__half__img}  >
                                    <img className={classes.imgSource} src={main_img2}  alt='tech'/>
                                </div>
                            </div>
                        </div >
                    </div>
                </div >
            </div>
            <div className='py-12' style={{backgroundColor: "#F6F7F9"}}>
                <div className={classes.mainBox__back} >
                    <div className={classes.box_content}>
                        <div className={classes.content__detail}>
                            <div className={classes.inlineBox__first}>
                                <div className={classes.inlineBox__cert}>

                                    {media960 === true ?
                                    <h2 className='tech_cert_tit'> {t('tech16')} {t('tech16-1')} </h2>
                                    :
                                    <h2 className='tech_cert_tit'> {t('tech16')} <br/> {t('tech16-1')} </h2>
                                    }
                                    <p className='tech_cert_sub'> 
                                        {t('tech17')} 
                                    </p>
                                </div>
                                <div className='d-flex flex-md-row flex-column'>
                                    <div className={classes.inlineBox__cert__half}  >
                                        <div >
                                            <div className='d-flex'>
                                                <img src={ic_cert} className='ic_cert mx-2' alt='tech'/>
                                                <p className='cert_txt'> {t('tech18')} </p><br/>
                                            </div>
                                        </div>
                                        <div >
                                            <div className='d-flex'>
                                                <img src={ic_cert} className='ic_cert mx-2' alt='tech'/>
                                                <p className='cert_txt'> {t('tech19')} </p><br/>
                                            </div>
                                        </div>
                                        <div >
                                            <div className='d-flex'>
                                                <img src={ic_cert} className='ic_cert mx-2' alt='tech'/>
                                                <p className='cert_txt'> {t('tech20')} </p><br/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.inlineBox__cert__half}  >
                                        <div >
                                            <div className='d-flex'>
                                                <img src={ic_cert} className='ic_cert mx-2' alt='tech'/>
                                                <p className='cert_txt'> {t('tech21')} </p><br/>
                                            </div>
                                        </div>
                                        <div >
                                            <div className='d-flex'>
                                                <img src={ic_cert} className='ic_cert mx-2' alt='tech'/>
                                                <p className='cert_txt'> {t('tech22')} </p><br/>
                                            </div>
                                        </div>
                                        <div >
                                            <div className='d-flex'>
                                                <img src={ic_cert} className='ic_cert mx-2' alt='tech'/>
                                                <p className='cert_txt'> {t('tech23')} </p><br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
                
            </div >
        </div>
    )
}